import { SET_PAGE_ALIAS } from '../actions/types';

export default function pageAlias(state = '', action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_PAGE_ALIAS:
				return action.payload;

			// no default
		}
	}

	return state;
}
