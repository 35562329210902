import { hysteriFetch, wrappers } from 'hysterics';

const modelSelectorService = hysteriFetch('ws-model-selector-data-service', {
	[wrappers.timeout.contextKey]: {
		timeout: 3000
	}
});

export const fetchModelSelectorData = async (
	pageAlias,
	imagePerspective,
	excludeModelsWithoutInventory
) => {
	let endpoint = `/apis/widget/${pageAlias}:model-selector-data-bus1/getModelSelectorData?imagePerspective=${imagePerspective}`;

	const searchParams = new URLSearchParams(window.location.search);
	const ddcpreview = searchParams.get('_ddcpreview');

	if (ddcpreview) {
		endpoint = `${endpoint}&_ddcpreview=${ddcpreview}`;
	}

	if (excludeModelsWithoutInventory) {
		endpoint = `${endpoint}&excludeModelsWithoutInventory=${excludeModelsWithoutInventory}`;
	}

	return modelSelectorService(endpoint);
};
