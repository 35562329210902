import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import {
	usePrefs,
	useLabels,
	useSitemap,
	useRequestData
} from 'wsm-common-data';

import GridCard from '../cards/Grid';
import { getModelLink } from '../../utils/getModelLink';

const GridContainer = ({ vehicles }) => {
	const prefs = usePrefs();
	const labels = useLabels();
	const sitemap = useSitemap();
	const { displayStyle, initialVehicleDisplay } = usePrefs();
	const { deviceType, widgetName, windowId } = useRequestData();

	const [vehiclesAux, setVehiclesAux] = React.useState(
		vehicles.slice(0, initialVehicleDisplay)
	);

	const buttonLabel = `${labels.get('SHOW')} ${
		vehicles.length - vehiclesAux.length
	} ${labels.get('MORE').toLowerCase()}`;

	const { modelLinkAlias, modelLinkParameters } = prefs;

	const containerClassnames = setClassNames([
		'model-card-grid-container',
		'py-4',
		'px-md-0',
		'py-md-8'
	]);

	const colClassNames = setClassNames([
		'col-xs-12',
		'col-sm-6',
		'col-md-6',
		'col-lg-4',
		'my-4',
		'px-4',
		'd-flex'
	]);

	const shouldRenderButton =
		deviceType === 'DESKTOP' &&
		vehiclesAux.length < vehicles.length &&
		displayStyle === 'grid';

	const handleShowMore = () => {
		setVehiclesAux(vehicles);
		trackEvent(widgetName, windowId, {
			action: 'clicked',
			element: 'View More Link',
			elementCTA: buttonLabel,
			result: 'Displayed more Models'
		});
	};

	React.useEffect(() => {
		const elementToFocus = document.getElementById(
			`card-${initialVehicleDisplay}`
		);
		if (elementToFocus) {
			setTimeout(() => {
				elementToFocus?.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}, 200);
		}
	}, [vehiclesAux, initialVehicleDisplay]);

	return (
		<>
			<div
				className={containerClassnames}
				data-testid="model-card-grid-container"
			>
				{React.Children.toArray(
					vehiclesAux.map((vehicle, i) => {
						const cardKey = `${vehicle.oemBodyStyle
							.toLowerCase()
							.replace(/ /g, '-')}-${i}`;

						return (
							<div
								key={cardKey}
								id={`card-${i}`}
								className={colClassNames}
								data-testid="model-card-grid-container-vehicle"
							>
								<GridCard
									modelObject={vehicle}
									modelLink={getModelLink(
										`${sitemap.get(modelLinkAlias)}`,
										modelLinkParameters,
										vehicle
									)}
								/>
							</div>
						);
					})
				)}
			</div>

			{shouldRenderButton && (
				<div className="w-100 d-flex pt-8 justify-content-center ">
					<button
						type="button"
						title={buttonLabel}
						aria-label={buttonLabel}
						onClick={handleShowMore}
						className="btn btn-default"
						data-testid="show-more-button"
					>
						{buttonLabel}
					</button>
				</div>
			)}
		</>
	);
};

GridContainer.propTypes = {
	vehicles: PropTypes.arrayOf(
		PropTypes.shape({
			year: PropTypes.number,
			make: PropTypes.string,
			model: PropTypes.string,
			gvBodyStyle: PropTypes.string,
			image: PropTypes.string,
			inventoryCount: PropTypes.number,
			minStartingAtPrice: PropTypes.number
		})
	).isRequired
};

export default GridContainer;
