export const getModelTitle = (modelTitleAttributes = ['model'], vehicle) => {
	const validAttributes = ['year', 'make', 'model', 'gvBodyStyle'];
	const modelTitleAttributesArray = modelTitleAttributes
		.split(',')
		.reduce((attributesArray, currentAttribute) => {
			const cleanedAttribute = currentAttribute.trim();
			if (validAttributes.includes(cleanedAttribute)) {
				attributesArray.push(`${vehicle[cleanedAttribute].toString()}`);
			}
			return attributesArray;
		}, []);

	return modelTitleAttributesArray.join(' ');
};
