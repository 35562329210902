import { useState, useEffect } from 'react';
import debounce from './debounce';

export const useDimensions = () => {
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight
	});

	useEffect(() => {
		const handleResize = debounce(() => {
			setScreenSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		});

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenSize;
};
