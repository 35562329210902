import React from 'react';
import { useSelector } from 'react-redux';
import { useAsyncResult } from 'wsm-error-boundary';
import { usePrefs, useRequestData } from 'wsm-common-data';
import { TabbedBodyStyles } from './TabbedBodyStyles';
import { Loading } from './Loading';
import { fetchModelSelectorData } from '../data/fetchModelSelectorData';

export const DataFetcher = () => {
	const prefs = usePrefs();
	const { deviceType } = useRequestData();
	const { pageAlias } = useSelector((state) => state);

	const imagePerspective =
		deviceType === 'MOBILE' || prefs.displayStyle === 'grid'
			? 'side-left'
			: 'front-left';

	const modelSelectorResult = useAsyncResult(
		async (alias, perspective) => {
			return fetchModelSelectorData(
				alias,
				perspective,
				prefs.excludeModelsWithoutInventory
			);
		},
		pageAlias,
		imagePerspective
	);

	if (modelSelectorResult.error) {
		throw modelSelectorResult.error;
	}

	if (modelSelectorResult.result) {
		return <TabbedBodyStyles data={modelSelectorResult.result} />;
	} else {
		return <Loading />;
	}
};
