import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';

import { BodyStyleContainer } from './BodyStyleContainer';

import Tab from './Tab';
import Tabs from './Tabs';

export const TabbedBodyStyles = ({ data }) => {
	const { tabOrder, showAllModelsTab, displayStyle } = usePrefs();
	const tabSliderRef = React.useRef();

	const dataAux = { ...data };
	const bodyStyleArray = Object.keys(dataAux);
	let tabNames = bodyStyleArray;

	if (tabOrder) {
		tabNames = tabOrder.split(',').reduce((tabNamesArray, tabName) => {
			const gvBodyStyle = bodyStyleArray.find(
				(dataBodyStyle) =>
					dataBodyStyle.toLowerCase() === tabName.trim().toLowerCase()
			);
			if (gvBodyStyle && !tabNamesArray.includes(gvBodyStyle)) {
				tabNamesArray.push(gvBodyStyle);
			}
			return tabNamesArray;
		}, []);
	}

	let defaultTab;
	if (showAllModelsTab === 'true') {
		if (displayStyle === 'largeVehicle') {
			tabNames.push('All');
		} else {
			tabNames.unshift('All');
		}
		defaultTab = 'All';

		const allCarts = Object.values(dataAux).reduce((prev, curr) => {
			const prevAux = [...prev];
			return curr.concat(prevAux);
		}, []);

		dataAux.All = [...allCarts];
	}

	if (!defaultTab) {
		[defaultTab] = tabNames;
	}

	const [activeTab, setActiveTab] = useState(tabNames[0]);

	const getTabContent = (tabTitle) => {
		const tabVehicles = dataAux[tabTitle];
		if (tabVehicles) {
			return <BodyStyleContainer vehiclesArray={tabVehicles} />;
		} else {
			return null;
		}
	};

	const tabArray = React.Children.toArray(
		tabNames.map((tabName) => (
			<Tab
				title={tabName}
				eventKey={tabName}
				tabClassName="tab-item ddc-font-size-xsmall mx-2"
			>
				{getTabContent(tabName)}
			</Tab>
		))
	);

	return (
		<Tabs
			ref={tabSliderRef}
			activeKey={activeTab}
			onSelect={setActiveTab}
			id="tabbed-model-selector"
			defaultActiveKey={defaultTab}
		>
			{tabArray}
		</Tabs>
	);
};

TabbedBodyStyles.propTypes = {
	data: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				year: PropTypes.number,
				make: PropTypes.string,
				model: PropTypes.string,
				gvBodyStyle: PropTypes.string,
				image: PropTypes.string,
				inventoryCount: PropTypes.number
			})
		)
	).isRequired
};
