import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs, useSitemap } from 'wsm-common-data';

import Slider from '../Slider';
import { getModelLink } from '../../utils/getModelLink';
import MobileCarouselCard from '../cards/MobileCarousel';

const MobileCarousel = ({ vehicles }) => {
	const prefs = usePrefs();
	const sitemap = useSitemap();

	const { modelLinkAlias, modelLinkParameters } = prefs;

	return (
		<Slider sliderContentClassnames="vehicles-carousel d-flex">
			{React.Children.toArray(
				vehicles.map((vehicle) => (
					<MobileCarouselCard
						modelObject={vehicle}
						modelLink={getModelLink(
							`${sitemap.get(modelLinkAlias)}`,
							modelLinkParameters,
							vehicle
						)}
					/>
				))
			)}
		</Slider>
	);
};

export default MobileCarousel;

MobileCarousel.propTypes = {
	vehicles: PropTypes.arrayOf(
		PropTypes.shape({
			year: PropTypes.number,
			make: PropTypes.string,
			model: PropTypes.string,
			image: PropTypes.string,
			gvBodyStyle: PropTypes.string,
			oemBodyStyle: PropTypes.string,
			inventoryCount: PropTypes.number
		})
	).isRequired
};
